.home-bg{
  background-image: url("./assets/images/Homebg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
.about-bg{
  background-image: url("./assets/images/about-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

@media screen and (max-width: 630px) {
  .home-bg{
    background-image: url("./assets/images/Mobile-bg.png");
  }
}
.wecrd{
  background: linear-gradient(180deg, rgba(0, 200, 64, 0.23) 0%, rgba(0, 11, 23, 0.23) 100%);
border: 1px solid #51F585;
width: 100%;

}